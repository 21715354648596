interface Skill {
	name: string;
	slug: string;
	category:
		| 'version_control'
		| 'cloud'
		| 'os'
		| 'ide'
		| 'code'
		| 'cms'
		| 'framework'
		| 'library'
		| 'testing'
		| 'preprocessor'
		| 'build'
		| 'pipeline'
		| 'browser'
		| 'project_management'
		| 'workspace'
		| 'database'
		| 'design';
	variant: string;
	type: 'image' | 'icon';
	isTopSkill?: boolean;
	expertise?: number;
	amount?: number;
}

const skills: Skill[] = [
	{
		name: 'HTML5',
		slug: 'html5',
		variant: 'original',
		type: 'image',
		category: 'code',
		isTopSkill: true,
		expertise: 90,
	},
	{
		name: 'CSS3',
		slug: 'css3',
		variant: 'original',
		type: 'image',
		category: 'code',
		isTopSkill: true,
		expertise: 80,
	},
	{
		name: 'JavaScript',
		slug: 'javascript',
		variant: 'original',
		type: 'image',
		category: 'code',
		isTopSkill: true,
		expertise: 80,
	},
	{
		name: 'Handlebars',
		slug: 'handlebars',
		variant: 'original',
		type: 'image',
		category: 'code',
	},
	{
		name: 'TypeScript',
		slug: 'typescript',
		variant: 'original',
		type: 'image',
		category: 'code',
		isTopSkill: true,
		expertise: 85,
	},
	{
		name: 'ESLint',
		slug: 'eslint',
		variant: 'original',
		type: 'image',
		category: 'code',
		expertise: 90,
	},
	{
		name: 'Node.js',
		slug: 'nodejs',
		variant: 'original',
		type: 'image',
		category: 'code',
		isTopSkill: true,
		expertise: 70,
	},
	{
		name: 'React',
		slug: 'react',
		variant: 'original',
		type: 'image',
		category: 'code',
		isTopSkill: true,
		expertise: 80,
	},
	{
		name: 'Next.js',
		slug: 'nextjs',
		variant: 'original',
		type: 'image',
		category: 'code',
		isTopSkill: true,
		expertise: 60,
	},
	{
		name: 'AngularJS',
		slug: 'angularjs',
		variant: 'original',
		type: 'image',
		category: 'code',
		isTopSkill: true,
		expertise: 70,
	},
	{
		name: 'Vue.js',
		slug: 'vuejs',
		variant: 'original',
		type: 'image',
		category: 'code',
		isTopSkill: true,
		expertise: 85,
	},
	{
		name: 'Nuxt.js',
		slug: 'nuxtjs',
		variant: 'original',
		type: 'image',
		category: 'code',
		expertise: 70,
	},
	{
		name: 'Vuetify',
		slug: 'vuetify',
		variant: 'original',
		type: 'image',
		category: 'library',
		expertise: 90,
	},
	{
		name: 'Material UI',
		slug: 'materialui',
		variant: 'original',
		type: 'image',
		category: 'library',
		expertise: 85,
	},
	{
		name: 'Storybook',
		slug: 'storybook',
		variant: 'original',
		type: 'image',
		category: 'code',
		expertise: 75,
	},
	{
		name: 'Jasmine',
		slug: 'jasmine',
		variant: 'plain',
		type: 'image',
		category: 'testing',
		expertise: 70,
	},
	{
		name: 'Mocha',
		slug: 'mocha',
		variant: 'plain',
		type: 'image',
		category: 'testing',
		expertise: 55,
	},
	{
		name: 'Jest',
		slug: 'jest',
		variant: 'plain',
		type: 'image',
		category: 'testing',
		isTopSkill: true,
		expertise: 80,
	},
	{
		name: 'D3.js',
		slug: 'd3js',
		variant: 'original',
		type: 'image',
		category: 'library',
		expertise: 75,
	},
	{
		name: 'jQuery',
		slug: 'jquery',
		variant: 'original',
		type: 'image',
		category: 'library',
		expertise: 90,
	},
	{
		name: 'SASS',
		slug: 'sass',
		variant: 'original',
		type: 'image',
		category: 'preprocessor',
		expertise: 90,
	},
	{
		name: 'LESS',
		slug: 'less',
		variant: 'plain-wordmark',
		type: 'image',
		category: 'preprocessor',
		expertise: 80,
	},
	{
		name: 'Gulp',
		slug: 'gulp',
		variant: 'plain',
		type: 'image',
		category: 'build',
		expertise: 60,
	},
	{
		name: 'NPM',
		slug: 'npm',
		variant: 'original-wordmark',
		type: 'image',
		category: 'build',
		expertise: 75,
	},
	{
		name: 'Yarn',
		slug: 'yarn',
		variant: 'original',
		type: 'image',
		category: 'build',
	},
	{
		name: 'Webpack',
		slug: 'webpack',
		variant: 'original',
		type: 'image',
		category: 'build',
	},
	{
		name: 'Babel',
		slug: 'babel',
		variant: 'original',
		type: 'image',
		category: 'build',
	},
	{
		name: 'PHP',
		slug: 'php',
		variant: 'original',
		type: 'image',
		category: 'code',
		isTopSkill: true,
		expertise: 75,
	},
	{
		name: 'WordPress',
		slug: 'wordpress',
		variant: 'original',
		type: 'image',
		category: 'cms',
		expertise: 80,
	},
	{
		name: 'WooCommerce',
		slug: 'woocommerce',
		variant: 'original',
		type: 'image',
		category: 'cms',
		expertise: 70,
	},
	{
		name: 'Drupal',
		slug: 'drupal',
		variant: 'original',
		type: 'image',
		category: 'cms',
	},
	{
		name: 'AWS Cloud',
		slug: 'amazonwebservices',
		variant: 'original',
		type: 'image',
		category: 'cloud',
		isTopSkill: true,
		expertise: 60,
	},
	{
		name: 'Google Cloud',
		slug: 'googlecloud',
		variant: 'original',
		type: 'image',
		category: 'cloud',
		isTopSkill: true,
		expertise: 75,
	},
	{
		name: 'Firebase',
		slug: 'firebase',
		variant: 'plain',
		type: 'image',
		category: 'cloud',
		isTopSkill: true,
		expertise: 85,
	},
	{
		name: 'Git',
		slug: 'git',
		variant: 'original',
		type: 'image',
		category: 'version_control',
		isTopSkill: true,
		expertise: 70,
	},
	{
		name: 'Subversion',
		slug: 'subversion',
		variant: 'original',
		type: 'image',
		category: 'version_control',
		expertise: 60,
	},
	{
		name: 'GitHub',
		slug: 'github',
		variant: 'original',
		type: 'image',
		category: 'version_control',
		expertise: 80,
	},
	{
		name: 'GitLab',
		slug: 'gitlab',
		variant: 'original',
		type: 'image',
		category: 'version_control',
	},
	{
		name: 'Bitbucket',
		slug: 'bitbucket',
		variant: 'original',
		type: 'image',
		category: 'version_control',
		expertise: 60,
	},
	{
		name: 'TortoiseGit',
		slug: 'tortoisegit',
		variant: 'original',
		type: 'image',
		category: 'version_control',
	},
	{
		name: 'CircleCI',
		slug: 'circleci',
		variant: 'plain',
		type: 'image',
		category: 'pipeline',
		expertise: 75,
	},
	{
		name: 'Jenkins',
		slug: 'jenkins',
		variant: 'original',
		type: 'image',
		category: 'pipeline',
	},
	{
		name: 'Heroku',
		slug: 'heroku',
		variant: 'original',
		type: 'image',
		category: 'pipeline',
	},
	{
		name: 'Google Chrome',
		slug: 'chrome',
		variant: 'original',
		type: 'image',
		category: 'browser',
	},
	{
		name: 'Firefox',
		slug: 'firefox',
		variant: 'original',
		type: 'image',
		category: 'browser',
	},
	{
		name: 'Opera',
		slug: 'opera',
		variant: 'original',
		type: 'image',
		category: 'browser',
	},
	{
		name: 'Trello',
		slug: 'trello',
		variant: 'plain',
		type: 'image',
		category: 'project_management',
	},
	{
		name: 'Jira',
		slug: 'jira',
		variant: 'original',
		type: 'image',
		category: 'project_management',
	},
	{
		name: 'Confluence',
		slug: 'confluence',
		variant: 'original',
		type: 'image',
		category: 'workspace',
	},
	{
		name: 'Docker',
		slug: 'docker',
		variant: 'original',
		type: 'image',
		category: 'build',
		isTopSkill: true,
		expertise: 70,
	},
	{
		name: 'MySQL',
		slug: 'mysql',
		variant: 'original-wordmark',
		type: 'image',
		category: 'database',
		expertise: 65,
	},
	{
		name: 'IntelliJ IDEA',
		slug: 'intellij',
		variant: 'original',
		type: 'image',
		category: 'ide',
	},
	{
		name: 'VS Code',
		slug: 'vscode',
		variant: 'original',
		type: 'image',
		category: 'ide',
	},
	{
		name: 'Photoshop',
		slug: 'photoshop',
		variant: 'plain',
		type: 'image',
		category: 'design',
	},
	{
		name: 'Figma',
		slug: 'figma',
		variant: 'original',
		type: 'image',
		category: 'design',
	},
	{
		name: 'Linux',
		slug: 'linux',
		variant: 'original',
		type: 'image',
		category: 'os',
	},
	{
		name: 'Ubuntu',
		slug: 'ubuntu',
		variant: 'plain',
		type: 'image',
		category: 'os',
	},
];

export default skills;
